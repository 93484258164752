/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM310D
 * 화면설명: 프사서 활동현황 탭
 * 접근권한: FC
 * 작 성 일: 2022.07.05
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container class="msp" title="프리미엄고객사랑서비스" :show-title="false" @on-scroll-bottom="fn_LoadingData()" ref="page" :topButton="true" :topButtonBottom="topButtonBottom">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-insideWidthAuto">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="collapsingHeaderView">
          <!-- 헤더 타이틀 영역 -->
            <template #fixed="{scrollRate}">
              <mo-top-bar color="primary" :scroll-target="scrollElementId" scroll-shadow>
                <div slot="nav" class="icon-wrapper">
                    <mo-icon color="white"  icon-size="36px" borderless @click="fn_HistoryBack">previous</mo-icon>
                </div>
                <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '프리미엄고객사랑서비스' : '프리미엄고객사랑서비스'}}</div>
                <div v-if="isMngr" slot="action"  @click="fn_OpenMSPCM116P()"><mo-icon>search</mo-icon></div>
              </mo-top-bar>
            </template>
            <!-- collapsing 영역 -->
            <template #collapsing>
              <div class="h1px" />
            </template>
            <template #sticky>
              <mo-tab-box default-idx="2" primary class="ns-move-tab-box">
                <!-- tab id loop -->
                <mo-tab-label idx="1" class="off" @click="$router.push({name: 'MSPCM303M'})">서비스신청</mo-tab-label>
                <mo-tab-label idx="2" class="on">활동현황</mo-tab-label>
                <!-- tab id loop //-->
              </mo-tab-box>
            </template>
            <template #scroll>
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
                <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="con-area">
                  <ur-box-container alignH="center" componentid="ur_box_container_003" direction="row" class="point-date-wrap mt30"> 
                    <mo-month-picker class="full"  v-model="searchDate" placeholder="2022-12" @confirmed="fn_DateSelected"/>
                  </ur-box-container>
                  <div class="attention-tip mt10" v-if="attTip1">
                    <mo-icon icon="msp-attention" class="att-icon">msp-attention</mo-icon>
                    <p class="att-txt ml5 fs16rem">{{unVstCustFgrM}}월 방문결과 미입력고객 <span class="fwb">{{pssBannerData.unVstCustFgr}}</span>명이 있습니다.</p>
                    <mo-icon icon="msp-close-bold" class="att-btn-close" @click="closeTip(!attTip1)">msp-close-bold</mo-icon>
                  </div>
                </ur-box-container>
                <!-- 총명수  -->
                <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum mt10" >
                  <mo-list-item>
                    <mo-checkbox v-model="isOneOrMoreCheck" :disabled="CMSrvcAplObjPnstaVOList.length === 0 || cnsltInfo.userId !== loginCnsltInfo.userId" @input="fn_CheckboxAllClick()" />
                    <div class="list-item__contents">
                      <div v-if="checkedCnt === 0" class="list-item__contents__title">
                        <span class="sum">총 <strong class="crTy-blue3">{{ CMSrvcAplObjPnstaVOList.length }}</strong> 명</span> 
                      </div>
                      <div v-else class="list-item__contents__title">
                        <span class="sum"><strong class="crTy-blue3" id="chkedCnt">{{ checkedCnt }}</strong> 명 선택</span> 
                      </div>
                    </div>
                    <span class="mr10">결과미입력</span>
                    <mo-switch v-model="resultInput" small class="ns-switch"/>
                  </mo-list-item>
                </ur-box-container>
                <!--목록-->
                <div class="full">
                  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-list pcls-list pb50">
                    <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-accodian ns-style2 ns-index-scroll-m"> -->
                      <mo-list :list-data="CMSrvcAplObjPnstaVOList" >
                        <template #list-item="{index, item}">
                          <mo-list-item expandable class="type-itm-2" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)"   >
                            <mo-checkbox v-model="item.checked" :disabled="cnsltInfo.userId !== loginCnsltInfo.userId" @input="fn_CheckboxClick()" :ref="`chk${index}`"></mo-checkbox>
                            <div class="list-item__contents" @touchstart="fn_CloseBs1">
                              <div class="list-item__contents__title fexTy3 mb0">
                                  <span class="name txtSkip  mrauto" @click.stop="fn_OpenCustInfoBs(item)">{{item.custNm}}</span>
                                  <span class="fc-badge-wrap">
                                    <mo-badge class="badge-sample-badge blue sm"  v-if="fn_GetVstYn(item.custSrvcRsltNm) ==='Y'" text="방문" shape="status">방문</mo-badge> 
                                    <mo-badge class="badge-sample-badge blue sm" v-if="fn_GetVstYn(item.custSrvcRsltNm) ==='N'"   text="미방문" shape="status">미방문</mo-badge> 
                                  </span>
                                <!-- <mo-badge class="badge-sample-badge blue" text="회사제공" shape="status">{{fn_GetCustSrvcTypCd(item.custSrvcTypCd)}}</mo-badge> -->
                              </div>
                              <div class="list-item__contents__info pt0">
                                <div class="info_box">
                                  <!--############황고은 프로 수정사항(생년월일, 핸드폰 번호 삭제) 요청일자:20240229-->
                                  <!-- <span class="crTy-bk1">{{$cmUtil.frmtDateStr(item.custBirthYmd, 'yyyy-mm-dd')}}</span><em>|</em>
                                  <span class="crTy-bk1">{{item.telno}}</span> -->
                                  <span class="crTy-blue3 fwb">{{fn_GetCustSrvcTypCd(item.custSrvcTypCd)}}</span>
                                </div>
                                <div class="list-item__contents__info fexTy2">
                                  <span class="min70 fs14rem crTy-bk7 mr10 valignT">신청</span><span class="crTy-bk1" >{{$cmUtil.frmtDateStr(item.custSrvcAplYmd, 'yyyy-mm-dd')}} / {{item.gftCntnt}}</span><!--v-html='item.content.application'-->
                                </div>
                                <div class="list-item__contents__info fexTy2">
                                  <span class="min70 fs14rem crTy-bk7 mr10 valignT">결과</span><span class="crTy-bk1">{{item.custSrvcRsltNm}}</span>
                                </div>
                                <div class="list-item__contents__info fexTy2">
                                  <!-- <span class="min70 fs14rem crTy-bk7 mr10 valignT">신계약</span><span class="crTy-bk1">{{item.prdtNm}}<span>{{$cmUtil.frmtDateStr(item.contYmd, 'yyyy-mm-dd')}}</span></span> -->
                                  <span class="min70 fs14rem crTy-bk7 mr10 valignT">신계약</span><span v-if="item.prdtNm" class="crTy-bk1">{{item.prdtNm}} / {{$cmUtil.frmtDateStr(item.contYmd, 'yyyy-mm-dd')}}</span>
                                </div>
                              </div>
                            </div>
                            <!-- 아코디언 컨텐츠 -->
                            <template v-slot:expand>
                              <div class="list-item-detail ml40">
                                <span>활동내역</span>
                                <div v-for="(item, idx) in item.touchList" :key="idx">
                                  <div class="contents-row">
                                    <span class="dsInline fs14rem min70 mr10">{{item.touchNm}}</span><span class="crTy-bk1">{{$cmUtil.frmtDateStr(item.touchYmd, 'yyyy-mm-dd')}}</span>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </mo-list-item>
                        </template>
                      </mo-list>
                    <!-- </ur-box-container> -->
                    <!-- 결과입력 팝업 -->
                    <div :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp pre overflow-h' : 'ns-bottom-sheet ns-style3 only-btn pre overflow-h'">
                      <mo-bottom-sheet ref="nsbottomsheet2" noHeader>
                        <template v-slot:action>
                          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                            <div class="relative-div">
                              <!-- <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM311P">상세조회</mo-button> -->
                              <!-- <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectItems.length > 0 && fn_Validate306(selectItems[0]) === 'pass' && hasUpdate === true"
                                @click="fn_OpenMSPCM306P" :disabled="selectItems[0].custSrvcAplYmd < lv_Today">
                                서비스 수정
                              </mo-button> -->
                              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectItems.length > 0" @click="fn_OpenMSPCM307P_BS">결과입력</mo-button> 
                            </div>
                          </ur-box-container>
                        </template>
                      </mo-bottom-sheet>
                    </div>
                  </ur-box-container>
                </div>
                <!-- 개인정보 이용 및 활용 동의 팝업 -->
                <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
                  <template v-slot:title>고객카드 생성</template>
                  <div class="customer-info-txt">
                    <!-- <strong class="name">{{ $bizUtil.omitStr(pObjmsg.custNm, 12) }}</strong> -->
                    <strong class="name">{{ pObjmsg.custNm }}</strong>
                    <p class="info">생년월일 <span class="birth">{{ $commonUtil.dateDashFormat(pObjmsg.birthDay) }}</span><em>|</em><span>{{ pObjmsg.gender }}</span></p>
                    <p class="ment">
                      등록된 고객이 아닙니다.<br>
                      고객카드 자동 생성을 클릭하면, 해당 고객은 보험상담 동의처리 없이 고객카드 생성이 바로 됩니다.
                    </p>
                  </div>
                  <template v-slot:action>
                    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                      <div class="relative-div">
                        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Btn_C_OnClick()">취소</mo-button>
                        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
                      </div>
                    </ur-box-container>
                  </template>
                </mo-bottom-sheet>
              </ur-box-container>
            </template>
          </mo-collapsing-header-view>
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <div :class="positionUp ? 'ns-height170' : ''"></div>
      </div>
    </ur-box-container>
    <!--팝업-->
    <mo-bottom-sheet ref="nsbottomsheet1"   class="ns-bottom-sheet" noHeader><!--:close-btn="false"-->
        <ul class="msp-customer-info-wrap">
          <li>
            <h6 class="crTy-bk7 fs16rem">제공 고객수</h6>
             <p class="mt0 mb0 fs19rem crTy-blue3" v-if="CMSrvcAplObjPnstaCmpxVO.profrCustFgr === undefined || CMSrvcAplObjPnstaCmpxVO.profrCustFgr === ''">0명</p>
            <p class="mt0 mb0 fs19rem crTy-blue3" v-else >{{CMSrvcAplObjPnstaCmpxVO.profrCustFgr}}명</p>
          </li>
          <li>
            <h6 class="crTy-bk7 fs16rem">총 터치</h6>
            <p class="mt0 mb0 fs19rem crTy-blue3"  v-if="CMSrvcAplObjPnstaCmpxVO.totTuchCustFgr === undefined || CMSrvcAplObjPnstaCmpxVO.totTuchCustFgr === ''" >0명</p>
            <p class="mt0 mb0 fs19rem crTy-blue3" v-else  >{{CMSrvcAplObjPnstaCmpxVO.totTuchCustFgr}} 명/ {{calculatePercentage}}</p>
          </li>
          <li>
            <h6 class="crTy-bk7 fs16rem">총 신계약</h6>
            <p class="mt0 mb0 fs19rem crTy-blue3"  v-if="CMSrvcAplObjPnstaCmpxVO.totNcontCnt === undefined || CMSrvcAplObjPnstaCmpxVO.totNcontCnt === ''">0건</p>
            <p class="mt0 mb0 fs19rem crTy-blue3" v-else >{{CMSrvcAplObjPnstaCmpxVO.totNcontCnt}}건</p>
          </li>
          <li>
            <h6 class="crTy-bk7 fs16rem">대상 신계약</h6>
            <p class="mt0 mb0 fs19rem crTy-blue3"  v-if="CMSrvcAplObjPnstaCmpxVO.ncontCnt === undefined || CMSrvcAplObjPnstaCmpxVO.ncontCnt === ''">0건</p>
            <p class="mt0 mb0 fs19rem crTy-blue3" v-else >{{CMSrvcAplObjPnstaCmpxVO.ncontCnt}}건</p>
          </li>
        </ul>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객명 검색
  import MSPCM120P from '@/ui/cm/MSPCM120P' // 메모
  import MSPCM128P from '@/ui/cm/MSPCM128P' // 수신고객 목록
  import MSPAP302D from '@/ui/ap/MSPAP302D' // 일정 추가
  import MSPCM311P from '@/ui/cm/MSPCM311P' // 상세조회
  import MSPCM307P from '@/ui/cm/MSPCM307P' // 프사서 결과입력
  import MSPCM307P_BS from '@/ui/cm/MSPCM307P_BS' // 프사서 방문결과부실입력금지
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM310D",
    screenId: "MSPCM310D",
    props: {
      param: {
        type: Object
      },
      pCnsltUserId: String
    },
    components: {
      MSPCM116P,
      MSPCM311P,
      MSPCM120P,
      MSPCM128P,
      MSPAP302D,
      MSPCM311P
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        
        inputCnt:'',
        nInputCnt:'',
        cnt:0,
        
         // 미방문 고객 수
        unVstCustFgr: 0,
         // 검색팝업의 고객명
        searchKeyword: '',
        // 검색팝업의 조직
        pOrgData303M: {},
        popup116: {}, // MSPCM116P 전체고객 목록 검색
        resultInput: false,
        check2: false,
        attTip1: true,
        isAfterFirstSearch: false,
        positionUp: false,
        // 지점장 여부
        isMngr: false,
        // 로그인 정보
        loginCnsltInfo: {},
        // 오늘신청 건 수정가능
        hasUpdate: false,
        // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        authCd: 'U000',
        selectItems: [],
        checkedCnt: 0,
        isOneOrMoreCheck : false,
        pssBannerData: {
          srvcAplName1: '',
          srvcAplName2: '',
          from: '',
          to: '',
          bannerTotCnt: 2,
          cnsltUserId: '',
          objYm: '',
          unVstCustFgr: 0,
          vstLmtStartDtm: '',
          vstLmtEndDtm: '',
          vstInptEndYmdNow : '',
        },
        unVstCustFgrM: '',
        
        // 스크롤
        lv_HeaderviewScrollCompID: '',   
        scrollElementId: '',
        // 고객카드 유무
        isCustCardExist: false,    
        // 선택한 월
        selectedDate: '',
        // 조회월
        searchDate: [],
        // 배너 스와이프
        swiperOption: {
          centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 10,
          autoplay: {
            delay: 20000,
            disableOnInteraction: false, 
          }
        },
        // 서비스신청가능시작일자
        srvcAplStrYmd: '',
        // 서비스신청가능종료일자
        srvcAplEndYmd: '',
        // 결과입력시작일자
        vstInptStrYmd: '',
        // 결과입력종료일자
        vstInptEndYmd: '',
        // 조회된 달의 결과입력시작일자
        vstInptStrYmdNow: '',
        // 조회된 달의 결과입력종료일자
        vstInptEndYmdNow: '',
        // 공통코드 리스트
        commCdList: [],
        // 고객리스트?
        CMCustSrvcObjInfoVOList: [],
        cnsltInfo: {},
        stndKeyList: [],
        stndKeyVal: 'start',
        pageRowCnt: '50',
        stndKeyValMain: 'start',
        // service roof count
        serviceCnt: 0,

        // 고객카드 생성 변수
        pObjmsg: { // 고객카드 생성 Obj
          cnsltNo: '', // 컨설턴트번호(ZA_CHNL_ID)
          custId: '', // 고객ID(PARTNER)
          custNm: '', // 고객명
          knb: '', // 주민번호
          birthDay: '', // 생년월일
          gender: '' // 성별
        },
        // 컨설턴트정보(변경가능)
        currentCnsltInfo: {
          userId: '',
          sarySbtrCnsntYn: '',
          onpstFofOrgNo: '',
          blckTypNm: '',
          onpstDofNo: ''
        },
        // month: '',
        CMSrvcAplObjPnstaCmpxVO: [],
        CMSrvcAplObjPnstaVOList: [],
        CUST_SRVC_TYP_CD: this.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD'),

        // 팝업 변수
        lv_inputParamS: {}, // 조회 Obj
        // 1. 전화
        iCCdCustMktPsbInfoVO: { // 고객카드의 동의(BP정보)
          mktCnvsMthTelYn: ''
        },
        cmCustCardInfoSVO: { // 고객 상세정보 인적사항
          chnlCustId: ' ',
          custId: ' ',
          telno: ''
        },
        // 2.문자
        custListObj: [], // 문자수신 고객 list
        // 4.메모
        member: { // 선택한 고객 정보(메모 팝업에 사용)
          custPtclrMatrCntnt: '', // 고객특이사항
          chnlCustId: '' // 채널고객 ID
        },
        // 검색팝업의 조직
        pOrgData310D: {},
        // 결과 미입력 리스트
        noResultList: [],
        allList: [],
        isFirstEnter: true,
        isServicePoss: true,
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     
    /** computed 함수 정의 영역 */
    computed: {
      calculatePercentage: function () {
        var lv_vm = this
        // let numerator = 15
        let numerator = lv_vm.CMSrvcAplObjPnstaCmpxVO.totTuchCustFgr
        let denominator = lv_vm.CMSrvcAplObjPnstaCmpxVO.profrCustFgr
        
        if (denominator === undefined || denominator === '0') 
     
        return 0
        var val = (numerator / denominator)
        var ret = ((Math.round(val + 'e+3' ) + 'e-3') * 100).toString()
        if(ret.length > 5){
          ret = ret.substring(0,4)
        }
        
        return ret + '%'
      },
      topButtonBottom () {
        // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
        if (this.positionUp) return 150
        else return 0
      },
      disableMonth () {
        return this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
      }
    },

     /** watch 정의 영역 */
    watch: {
      resultInput (nV, oV) {
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        // this.fn_SelListSrvcObjCust(nV)
        if (this.resultInput) {
          this.CMSrvcAplObjPnstaVOList = this.noResultList
        } else {
          this.CMSrvcAplObjPnstaVOList = this.allList
        }
        for (let i =0; this.CMSrvcAplObjPnstaVOList.length > 0;i++) {
          this.CMSrvcAplObjPnstaVOList[i].checked = false
        }
      }
      
    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      console.log('MSPCM310D created...')
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      // this.cnsltInfo.userId = this.$props.cnsltUserId
      this.cnsltInfo.userId = this.$route.params.cnsltUserId
      this.pssBannerData = window.vue.getStore('cmStore').getters.getState.pssBannerData
      console.log('pssBannerData>>>>', this.pssBannerData)
      this.loginCnsltInfo = this.getStore('userInfo').getters.getUserInfo
      this.lv_Today = moment(new Date()).format('YYYYMMDD') // 오늘 날짜
      //240304
      if(this.pssBannerData.unVstCustFgrYm.length === 6){
        this.unVstCustFgrM = this.pssBannerData.unVstCustFgrYm.substr(4,2)
        this.unVstCustFgr = this.pssBannerData.unVstCustFgr
      }
      console.log('!!??!!배너 노출', this.pssBannerData.vstInptEndYmd)
      console.log('this.lv_Today', this.lv_Today)
      let diff = Number(this.pssBannerData.vstInptEndYmd) - Number(this.lv_Today)
      console.log('diff>>>', diff)
      console.log('this.pssBannerData.unVstCustFgr>>>', this.pssBannerData.unVstCustFgr)
      if (Number(this.pssBannerData.unVstCustFgr) > 0 && ( diff<= 3 && diff >= 0 )) {
        this.attTip1 = true
      } else {
        this.attTip1 = false
      }

      this.searchDate.push(new Date().getFullYear().toString())
      let tempMonth = (new Date().getMonth() + 1).toString()
      if(tempMonth.length === 1){
        tempMonth = '0' + tempMonth
      }
      this.searchDate.push(tempMonth)
      this.selectedDate = this.searchDate[0]+this.searchDate[1]

      this.fn_CommCdList()
      //this.fn_GetInitDate()

     this.fn_Btn_S_OnClick()
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      console.log('MSPCM310D mounted...')
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM310D')
      if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
        // 지점장여부
        this.isMngr = true
      }
      console.log('지점장 여부>>>>',this.$cmUtil.isSrchOrgCombo(), this.isMngr)
      this.currentCnsltInfo.userId = ''
      this.$nextTick(() => {
        // 스크롤 애니메이션 instance 선언
        // pk.son 20220728 퍼블수정
        // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
        // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
        this.scrollElementId = this.$refs.collapsingHeaderView.getScrollElementId();
      })
      // page(tab) view count
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM303M

      if(pageCnt === 0){
        // 최초접근(사용안함)
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', -1)
      }else{
        // 프사서 서비스신청에서 넘어온 경우를 제외하곤 카운트 쌓지 않음
        if (pageCnt % 2 === -1) {
          window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', pageCnt - 1)
        }
      }

      this.fn_OpenBs1()
      this.fn_ServicePoss()
      this.$BottomManager.fn_SetBottomVisible(true)
    },

    /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      this.$BottomManager.fn_SetBottomVisible(true)
    },

    
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      closeTip(tip) {this.attTip1 = tip
        
      },
      fn_OnClickListItem(index) {
        if (this.cnsltInfo.userId !== this.loginCnsltInfo.userId) return
        this.$refs[`chk${index}`].onClick()
      },
      
      /*******************************************************
      * function명  : fn_CommCdList 
      * 설명        : 공통코드 조회(IF-BC-0020, txTSSBC13S7) Service1
      * param       : cdEngNmList(CUST_SRVC_TYP_CD, GFT_NO_CD, CUST_SRVC_RSLT_CD)
      * Return      : N/A
      ******************************************************/
      fn_CommCdList: function () {
        var lv_vm = this
        window.vue.getInstance('vue').$commonUtil.selListMassSliCommCd(['CUST_SRVC_TYP_CD', 'GFT_NO_CD', 'CUST_SRVC_RSLT_CD'])
          .then(function () {
            // 2-1. 고객유형 공통코드 데이터 형식 변경
            lv_vm.commCdList.CUST_SRVC_TYP_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
            lv_vm.commCdList.CUST_SRVC_RSLT_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_RSLT_CD')
            lv_vm.commCdList.GFT_NO_CD = lv_vm.getStore('code').getters.getState.get('GFT_NO_CD')
          })
      },

      
      /*******************************************************
       * Function명  : fn_GetCustSrvcTypCd
       * 설명        : 타입명
       ******************************************************/
      fn_GetCustSrvcTypCd: function (custSrvcTypCd) {
        var lv_vm = this
        lv_vm.custSrvcTypCdList = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
        return lv_vm.custSrvcTypCdList.filter(item => item.cdVal === custSrvcTypCd)[0].cdValNm
      },

      /*******************************************************
       * Function명  : fn_Btn_S_OnClick
       * 설명        : 조회버튼 클릭이벤트
       ******************************************************/
      fn_Btn_S_OnClick: function () {
       
        this.stndKeyList = []
        this.CMSrvcAplObjPnstaVOList.splice(0, this.CMSrvcAplObjPnstaVOList.length)
        this.stndKeyValMain = 'start'
         
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
       
        this.fn_InitSearch()
        
      },

      /******************************************************************************
      * Function명: fn_ServicePoss
      * 설명: 처리가능여부
      ******************************************************************************/
      fn_ServicePoss: function() {
        let lv_vm = this
        let value = ''
        this.$commonUtil.getServerTime().then( function (response) {
          value = moment(response).format('HH')
          if ( Number(value) >= 21 || Number(value) < 8) {
            // 21시 이후 서비스 중지 처리
            lv_vm.isServicePoss = false
          }
        })
      },

      /*******************************************************
      * Function명  : fn_InitSearch
      * 설명        : 프리미엄고객사랑서비스 활동현황 조회
      *               IF-CM-3016 txTSSCM51S6
      * param : cnsltNo, objYm
      * return :  CMSrvcAplObjPnstaSVO
      ******************************************************/
      fn_InitSearch: async function () {
        console.log('너 호출 안되니', this.stndKeyValMain , this.stndKeyVal)
        let lv_vm = this
        let trnstId = 'txTSSCM51S6'
        
        lv_vm.isAfterFirstSearch = false
        lv_vm.stndKeyVal = lv_vm.stndKeyValMain
        if (lv_vm.stndKeyVal === 'end') {
          return
        } else if (lv_vm.stndKeyVal === 'more') {
          lv_vm.isOneOrMoreCheck = false
        } else if (lv_vm.stndKeyVal === 'start') {
          lv_vm.isOneOrMoreCheck = false
        }

        let pParams = {
          cnsltNo: lv_vm.currentCnsltInfo.userId !== '' ? lv_vm.currentCnsltInfo.userId : lv_vm.cnsltInfo.userId,
          objYm: lv_vm.selectedDate,
          aplScCd: '0'
        }

        this.post(lv_vm, pParams, trnstId, 'S')
          .then(function (response) {
            console.log('왜 안나와ㅣㅣㅣ', response)
            if (response !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU116') {
              console.log('활동현황 목록>>>>', response)
              lv_vm.CMSrvcAplObjPnstaCmpxVO = response.body
              let responseList = lv_vm.CMSrvcAplObjPnstaCmpxVO.iCSrvcAplObjPnstaVO
              if (responseList !== null) {
                let inputCnt =0;
                let nInputCnt =0;
                let cnt =0;
                if (lv_vm.stndKeyVal == 'start') {
                lv_vm.allList.splice(0)
                lv_vm.noResultList.splice(0)
                }
                // 신청여부 정보 조회
                for (var i = 0; i < responseList.length; i++) {
                  let CMSrvcAplObjPnstaVOItem = responseList[i]
                  let custSrvcAplYmd = CMSrvcAplObjPnstaVOItem.custSrvcAplYmd
                  let excluRsnInptCd = CMSrvcAplObjPnstaVOItem.excluRsnInptCd
                  CMSrvcAplObjPnstaVOItem.prgStatNm = '신청'
                  CMSrvcAplObjPnstaVOItem.custSrvcTypNm = lv_vm.commCdList.CUST_SRVC_TYP_CD[CMSrvcAplObjPnstaVOItem.custSrvcTypCd]
                  if (!custSrvcAplYmd) {
                    if (excluRsnInptCd === '6') {
                      CMSrvcAplObjPnstaVOItem.prgStatNm = '제외(회수)'
                    } else if (excluRsnInptCd === '9') {
                      CMSrvcAplObjPnstaVOItem.prgStatNm = '제외(연기)'
                    } else if (excluRsnInptCd === 'a') {
                      CMSrvcAplObjPnstaVOItem.prgStatNm = '추가'
                    }
                  }
                  responseList[i].custSrvcRsltNm = lv_vm.fn_GetCustSrvcRsltNm(responseList[i].custSrvcRsltCd)
                  responseList[i].custSrvcTypNm = lv_vm.fn_GetCustSrvcTypCd(responseList[i].custSrvcTypCd)

                  //테스트
                  // responseList[i].rntTelTuchYmd= '20200104'
                  // responseList[i].rntTuchYmd01='20200102'
                  // responseList[i].rntVstYmd='20200103'
                  // responseList[i].avlStrYmd='20200108'
                  // responseList[i].rntTuchYmd03 ='20200107' 
                  // responseList[i].rntTuchYmd04 = '20200105'
                   responseList[i].touchList = []
                  if(lv_vm.fn_GetRealData(responseList[i].rntTelTuchYmd) !== ''){
                    responseList[i].touchList.push({touchNm : '전화', touchYmd : responseList[i].rntTelTuchYmd})
                  }else{
                    responseList[i].touchList.push({touchNm : '전화', touchYmd : ''})
                  }
                  if(lv_vm.fn_GetRealData(responseList[i].rntTuchYmd01) !== ''){
                    responseList[i].touchList.push({touchNm : 'e마케팅', touchYmd : responseList[i].rntTuchYmd01})
                  }else{
                    responseList[i].touchList.push({touchNm : 'e마케팅', touchYmd : ''})
                  }
                  if(lv_vm.fn_GetRealData(responseList[i].rntVstYmd) !== ''){
                    responseList[i].touchList.push({touchNm : '방문', touchYmd : responseList[i].rntVstYmd})
                  }else{
                    responseList[i].touchList.push({touchNm : '방문', touchYmd : ''})
                  }
                  if(lv_vm.fn_GetRealData(responseList[i].avlStrYmd) !== ''){
                    responseList[i].touchList.push({touchNm : '동의서', touchYmd : responseList[i].avlStrYmd})
                  }else{
                    responseList[i].touchList.push({touchNm : '동의서', touchYmd : ''})
                  }
                  if(lv_vm.fn_GetRealData(responseList[i].rntTuchYmd03) !== ''){
                    responseList[i].touchList.push({touchNm : '가설', touchYmd : responseList[i].rntTuchYmd03})
                  }else{
                    responseList[i].touchList.push({touchNm : '가설', touchYmd : ''})
                  }
                  if(lv_vm.fn_GetRealData(responseList[i].rntTuchYmd04) !== ''){
                    responseList[i].touchList.push({touchNm : '보장설계', touchYmd : responseList[i].rntTuchYmd04})
                  }else{
                    responseList[i].touchList.push({touchNm : '보장설계', touchYmd : ''})
                  }
                  responseList[i].touchList.sort(function (a, b) {
                    if (a.touchYmd !== b.touchYmd) {
                      return Number(a.touchYmd) > Number(b.touchYmd) ? -1 : 1
                    }
                  })
                  responseList[i].objYm = lv_vm.selectedDate
                  responseList[i].contNo = responseList[i].contNo01 ? responseList[i].contNo01 : responseList[i].contNo02
                  if (lv_vm.$bizUtil.isEmpty(responseList[i].custSrvcRsltCd)) { // 결과 미입력
                    cnt++
                    lv_vm.noResultList.push(responseList[i])
                  } else {
                    console.log(`${i}번째 결과입력>>`, responseList[i].custSrvcRsltCd)
                  }
                  lv_vm.allList.push(responseList[i])
                  if (lv_vm.stndKeyVal !== 'start' && i+1 === responseList.length) {
                    lv_vm.$refs.collapsingHeaderView.adjustHeight()
                  }
                } // for 종료
                console.log('활동현황 전체목록>>', lv_vm.allList)
                console.log('활동현황 결과 미입력 목록>>', lv_vm.noResultList)
                if (lv_vm.resultInput) { // 결과 미입력 목록
                  console.log('결과 미입력 목록')
                  // lv_vm.noResultList[0].custNm = '미입력대상자입니다.'
                  lv_vm.CMSrvcAplObjPnstaVOList = lv_vm.noResultList
                } else {
                  console.log('모든 목록')
                  lv_vm.CMSrvcAplObjPnstaVOList = lv_vm.allList
                }
                console.log('활동현황 최종 목록>>', lv_vm.CMSrvcAplObjPnstaVOList )
                
                // if (lv_vm.stndKeyVal !== 'start') {
                //   for (var j = 0; j < responseList.length; j++) {
                //     lv_vm.CMSrvcAplObjPnstaVOList.push(responseList[j])
                //     if(j+1 === responseList.length){
                //       lv_vm.$refs.collapsingHeaderView.adjustHeight()
                //     }
                //   }
                // } else {
                //   lv_vm.CMSrvcAplObjPnstaVOList = responseList
                // }
              } else { // 목록이 0건
                console.log('목록 0건!!')
              }
              
              lv_vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_vm.stndKeyList === null || lv_vm.stndKeyList.length === 0 || lv_vm.stndKeyList[0] === null || lv_vm.stndKeyList[0].stndKeyVal === null || lv_vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_vm.stndKeyValMain = 'end'
              } else {
                lv_vm.stndKeyValMain = 'more'
              }
              
            } else {
              lv_vm.$addSnackbar('오류가 발생했습니다. 다시 시도해주십시오')
            }
            
            lv_vm.fn_GetDetailCtrl()
          })
      },

      /*********************************************************
      * Function명: fn_OpenMSPCM306P
      * 설명: 프사서 신청
      *********************************************************/
      fn_OpenMSPCM306P: async function (param) {
        let lv_vm = this
        let lv_flag = true
        let from = '';
        let to = '';
        if (this.isServicePoss === false) {
          this.$addSnackbar('지금은 서비스신청 가능 시간이 아닙니다.\n(신청시간 : 08:00 ~ 21:00)')
          return
        }
        lv_vm.selectedCustInfo = (param.custId) ? param : Object.assign([], this.selectItems[0])

        // 1. 기신청한 경우 팝업 출력
        lv_flag = lv_vm.fn_Validate306(lv_vm.selectedCustInfo)

        if (lv_flag === 'date') {
          let msg = {}
          msg.startMonth = lv_vm.srvcAplStrYmd.substring(4, 6)
          msg.startDay = lv_vm.srvcAplStrYmd.substring(6, 8)
          msg.endMonth = lv_vm.srvcAplEndYmd.substring(4, 6)
          msg.endDay = lv_vm.srvcAplEndYmd.substring(6, 8)
          lv_vm.$emit('openPopup', 'alert2', msg)

          from = Number(startMonth) + '월 ' + Number(startDay) + '일'
          to = Number(endMonth) + '월 ' + Number(endDay) + '일'
          
          lv_vm.$addSnackbar('신청기간이 아닙니다.\n신청기간은 ' + from + ' 부터 ' + to + ' 까지 입니다.')
          return
        } else if (lv_flag === 'pass') {
          let isAddPossible = await lv_vm.fn_SelListMyCustAddInfo(lv_vm.selectedCustInfo)
          if (!isAddPossible) {
            lv_vm.$addSnackbar('신청가능 고객 수를 초과하였습니다')
            return
          }
        }
        // 해당 고객의 유형에 대해 서비스 신청이 가능한지 확인
        lv_vm.selectedCustInfo.flag = lv_flag
        lv_vm.selectedCustInfo.objYm = lv_vm.selectedDate
        lv_vm.selectedCustInfo.custSrvcTypCdNm = lv_vm.commCdList.CUST_SRVC_TYP_CD.filter(item => item.cdVal === lv_vm.selectedCustInfo.custSrvcTypCd)[0].cdValNm
        this.popup306 = this.$bottomModal.open(MSPCM306P, {
          
          properties: {
            pPage: 'MSPCM303M',
            selectItems: lv_vm.selectItems,
            selectedCustInfo: lv_vm.selectedCustInfo, // 고객정보
          },
          listeners: {
            onPositive: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup306)
              lv_vm.$refs.nsbottomsheet2.close()
              lv_vm.fn_UpdSrvcAplInfo(pData)
              lv_vm.selectItems = []
              lv_vm.checkedCnt = 0
              lv_vm.isOneOrMoreCheck = false
              
            },
            onNegative: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup306)
            },
          }
        })
      },

      /*******************************************************
      * function명  : fn_GetRealData
      * 설명        : 비어있는데이터 정제
      ******************************************************/
      fn_GetRealData: function(val){
        let returnValue = ''

        if(val !== null && val !== '0' && val !== ''){
          returnValue = val
        }
        return returnValue
      },

      /*******************************************************
      * function명  : fn_GetDetailCtrl
      * 설명        : 신청대상 조회
      ******************************************************/
      fn_GetDetailCtrl: function(){
        for (let i = 0; i < this.CMSrvcAplObjPnstaVOList.length; i++) {
         this.fn_SelListSrvcObjCust(this.CMSrvcAplObjPnstaVOList[i].custNm, this.CMSrvcAplObjPnstaVOList[i].custSrvcTypCd, this.CMSrvcAplObjPnstaVOList[i].srvcObjCustScCd, this.CMSrvcAplObjPnstaVOList[i].custId)
        }
      },

      /*******************************************************
      * function명  : fn_SelListSrvcObjCust
      * 설명        : 프리미엄고객사랑서비스 대상 조회
      *               IF-CM-3004 txTSSCM51S1
      * param       : cnsltNo, objYm, custSrvcTypCd, selfCnfYn, blckTypNm, custNm(추가예정)
      * Return      : OBJECT
      ******************************************************/
      fn_SelListSrvcObjCust: function (custNm, srvcType, srvcObjCustScCd, custId) {
        let lv_vm = this
        // let trnstId = 'txTSSCM51S1'
        let trnstId = 'txTSSCM48S1'
        
        lv_vm.stndKeyVal = 'start'
        // 조회 파라미터 복사
        lv_vm.pSelectParam = {
          custSrvcAplYmd: '',
          cnsltNo: lv_vm.currentCnsltInfo.userId !== '' ? lv_vm.currentCnsltInfo.userId : lv_vm.cnsltInfo.userId,
          objYm: lv_vm.selectedDate,
          custSrvcTypCd: srvcType,
          blckTypNm: '',//(lv_vm.currentCnsltInfo.blckTypNm === 'N') ? '' : lv_vm.currentCnsltInfo.blckTypNm,
          custNm: custNm,
          custSrvcScCd: '1'//(lv_vm.selectedCustType === '02') ? '3' : '1'// 1-프사서, 2-스사서 //
        }
        console.log('활동현황 대상조회 건건>>>', lv_vm.pSelectParam)
        lv_vm.post(lv_vm, lv_vm.pSelectParam, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU001') {
              if (response.body.iCCustSrvcObjInfoVO != null) {
                lv_vm.CMCustSrvcObjInfoVOList = response.body.iCCustSrvcObjInfoVO
                // 서비스신청가능시작일자
                lv_vm.srvcAplStrYmd = response.body.srvcAplStrYmd
                // 서비스신청가능종료일자
                lv_vm.srvcAplEndYmd = response.body.srvcAplEndYmd
                // 결과입력시작일자
                if (response.body.vstInptStrYmd !== '') {
                  lv_vm.vstInptStrYmdNow = response.body.vstInptStrYmd
                }
                // 결과입력종료일자
                if (response.body.vstInptEndYmd !== '') {
                  lv_vm.vstInptEndYmdNow = response.body.vstInptEndYmd
                }
                lv_vm.serviceCnt++
                for (let i = 0; i < lv_vm.CMCustSrvcObjInfoVOList.length; i++) {
                  for(let j= 0; j < lv_vm.CMSrvcAplObjPnstaVOList.length; j++){
                    if(lv_vm.CMCustSrvcObjInfoVOList[i].custId === lv_vm.CMSrvcAplObjPnstaVOList[j].custId){
                    lv_vm.CMSrvcAplObjPnstaVOList[j].gftNoCd = lv_vm.CMCustSrvcObjInfoVOList[i].gftNoCd
                    // lv_vm.CMSrvcAplObjPnstaVOList[j].custSrvcRsltCd = lv_vm.CMCustSrvcObjInfoVOList[i].ustSrvcRsltCd
                    lv_vm.CMSrvcAplObjPnstaVOList[j].rrn = lv_vm.CMCustSrvcObjInfoVOList[i].rrn
                    lv_vm.CMSrvcAplObjPnstaVOList[j].rrnFrnoNo = lv_vm.CMCustSrvcObjInfoVOList[i].rrnFrnoNo
                    lv_vm.CMSrvcAplObjPnstaVOList[j].custSrvcAplNo = lv_vm.CMCustSrvcObjInfoVOList[i].custSrvcAplNo
                    if(lv_vm.$bizUtil.isEmpty(lv_vm.CMCustSrvcObjInfoVOList[i].chnlCustId)){
                      lv_vm.CMSrvcAplObjPnstaVOList[j].chnlCustId = ''
                    }else{
                      lv_vm.CMSrvcAplObjPnstaVOList[j].chnlCustId = lv_vm.CMCustSrvcObjInfoVOList[i].chnlCustId
                    }
                    if(lv_vm.CMSrvcAplObjPnstaVOList[j].gftCntnt === ''){
                      let gftlst = lv_vm.getStore('code').getters.getState.get('GFT_NO_CD')
                      for(let k = 0; k < gftlst.length; k++){
                        if(gftlst[k].cdVal === lv_vm.CMSrvcAplObjPnstaVOList[j].gftNoCd){
                          lv_vm.CMSrvcAplObjPnstaVOList[j].gftCntnt = gftlst[k].cdValNm
                        }
                      }
                    }
                  }
                }
              }

            } else {
              lv_vm.CMCustSrvcObjInfoVOList = []
            }
            lv_vm.isFirstEnter = false
          } else {
            lv_vm.$addSnackbar('[고객조회] 오류가 발생했습니다. 다시 시도해주십시오')
          }
          if(lv_vm.serviceCnt === lv_vm.CMSrvcAplObjPnstaVOList.length){
            //lv_vm.$refs.collapsingHeaderView.adjustHeight()
          }
        })
      },

      /*******************************************************
      * Function명  : fn_LoadingData
      * 설명        : 프리미엄고객사랑서비스 활동현황 조회(loading 시)
      ******************************************************/
      fn_LoadingData: function () {
       
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
      
        this.fn_InitSearch()
        
       
      },

     
      /******************************************************************************
      * Function명 : fn_GetVstYn
      * 설명       : 방문결과
      ******************************************************************************/      
      fn_GetVstYn: function (custSrvcRsltNm) {
        
        let returnValue = custSrvcRsltNm.includes('방문');
        let returnValue2 = custSrvcRsltNm.includes('미방문');
        if(returnValue){
            returnValue = 'Y'
        }
        if(returnValue2){
          returnValue = 'N'
        } 
        console.log("custSrvcRsltCd>>>"+custSrvcRsltNm+"returnValue>>"+returnValue)
        return returnValue
          
      },
      /*******************************************************
      * Function명  : fn_GetCustSrvcRsltNm
      * 설명        : 방문/미방문/사유
      ******************************************************/
      fn_GetCustSrvcRsltNm: function (custSrvcRsltCd) {
        var returnValue = ''
        var str1 = '방문'
        var str2 = '미방문'

        switch (custSrvcRsltCd) {
           
          case 'a':
            returnValue = str1 + '(기계약상황확인)'
            break;
          case 'b':
            returnValue = str1 +  '(컨설팅(통합자산분석,보장분석)'
            break;
          case 'c':
            returnValue = str1 +  '(방문물품만전달)'
            break;
          case 'd':
            returnValue = str1 +  '(보험금청구)'
            break;
          case 'e':
            returnValue = str1 +  '(신상품/이벤트소개)'
            break;
          case '3':
            returnValue = str2 +  '(고객변심으로방문불가)'
            break;
          case '4':
            returnValue = str2 +   '(FC개인사정으로미방문)'
            break;
          case '5':
            returnValue = str2 +   '(담당FC해촉)'
            break;
          // 추가 2022-08-17
          case '1':
            returnValue = str2 +   '(방문전달 완료)'
            break;
          case '2':
            returnValue = str2 +   '(택배)'
            break;
          case 'f':
            returnValue = str2 +   '(고객정보 업데이트 및 반영)'
            break;
          default:
            returnValue = ''
            break;
        }
        console.log("returnValue>>>>>"+returnValue);
        return returnValue
      },

      /*******************************************************
      * function명  : fn_CheckboxClick
      * 설명        : 체크박스 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CheckboxClick: function () {
        this.selectItems = this.CMSrvcAplObjPnstaVOList.filter(item => item.checked === true)
        this.checkedCnt = this.selectItems.length

        if(this.CMSrvcAplObjPnstaVOList.length === this.checkedCnt){
          this.isOneOrMoreCheck = true
        }else{
          this.isOneOrMoreCheck = false
        }
        console.log('ssssss', this.selectItems[0])
        console.log('당일>>', this.lv_Today)
        // 기신청고객 : 신청이후 하루가 지난 고객 
        if(this.checkedCnt === 1){
          if(this.fn_Validate306(this.selectItems[0]) !== 'alrRequested'){
            this.alrRequested = true
            
          }
          if (this.selectItems[0].custSrvcAplYmd.length > 0) {
            if (this.lv_Today === this.selectItems[0].custSrvcAplYmd) {
              this.hasUpdate = true
            }else{
              this.hasUpdate = false
            }
          }else{
            this.hasUpdate = false
          }
        }
        this.CMSrvcAplObjPnstaVOList.push()
        this.fn_CtrlBottomSheet()
      },

      /******************************************************************************
      * Function명: fn_Validate306
      * 설명: input값에 대한 validation 수행함수
      ******************************************************************************/     
      fn_Validate306: function (param) {
        let lv_vm = this
        let lv_custInfo = param
        let flag = 'pass'
      
        if (lv_custInfo.custSrvcAplYmd.length > 0) {
          if (this.lv_Today !== lv_custInfo.custSrvcAplYmd) {
            flag = 'alrRequested'
          }
        } else if (lv_custInfo.gftNoCd.length > 0 ||
                        lv_custInfo.gftCntnt.length > 0 ||
                              lv_custInfo.srvcInflPathCd.length > 0) {
          flag = 'alrRequested'
        }
        if (flag === 'pass') {
          //데이터 널값
          if (Number(this.lv_Today) < Number(lv_vm.srvcAplStrYmd) || Number(this.lv_Today) > Number(lv_vm.srvcAplEndYmd)) {
            flag = 'date'
          }
        }
        return flag
      },

      /*******************************************************
      * function명  : fn_CheckboxAllClick
      * 설명        : 체크박스 전체 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CheckboxAllClick: function () {
        for(let i = 0; i < this.CMSrvcAplObjPnstaVOList.length; i++){
            this.CMSrvcAplObjPnstaVOList[i].checked = this.isOneOrMoreCheck
        }
        this.CMSrvcAplObjPnstaVOList.push()
        if(this.isOneOrMoreCheck){
          this.selectItems = this.CMSrvcAplObjPnstaVOList
        }else{
          this.selectItems = []
        }
        // this.$commonUtil.updateDisplay(this.$refs.updateContainer)
        this.checkedCnt = this.selectItems.length
        this.fn_CtrlBottomSheet()
      },

      /*******************************************************
      * function명  : fn_CtrlBottomSheet
      * 설명        : 체크박스 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CtrlBottomSheet: function () {
        if (this.checkedCnt > 0) {
          // 터치아이콘 show 홈아이콘 hide
          this.positionUp = false
          // 활동내역 버튼 hide
          this.fn_CloseBs1()
        }else if(this.checkedCnt === 0){
          // 터치아이콘 hide 홈아이콘 show
          this.positionUp = false
        }
        if(this.checkedCnt === 1){
          // 상세보기 버튼
          this.$refs.nsbottomsheet2.open()

          this.positionUp = true
        }else{
          this.$refs.nsbottomsheet2.close()
          this.positionUp = false
        }

        for (let i = 0; i < this.selectItems.length; i++) {
          if(this.$bizUtil.isEmpty(this.selectItems[i].chnlCustId)){
            this.selectItems[i].chnlCustId = ''
          }
        }

        this.isCustCardExist = this.selectItems.filter(item =>(item.chnlCustId.trim() === '')).length === 0

        this.$cmUtil.setSelectedCustList(this, this.selectItems) // 고객 체크 바텀시트
      },


      /*******************************************************
      * function명  : fn_DateSelected
      * 설명        : 달력 선택
      * param       : any
      * Return      : N/A
      ******************************************************/
      fn_DateSelected: function (value) {
        if(this.$refs !== undefined && this.$refs.nsbottomsheet2 !== undefined){
          this.$refs.nsbottomsheet2.close()
        }
        this.isFirstEnter = true
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        this.resultInput = false
        this.selectedDate = value.substring(0,4)+value.substring(5,7)
        this.fn_OpenBs1()
        this.fn_Btn_S_OnClick()
      },

      
      
      /*******************************************************
      * function명  : fn_OpenCustInfoBs
      * 설명        : 고객상세 팝업 연동
      * param       : String
      * Return      : N/A
      ******************************************************/
      fn_OpenCustInfoBs: function (item) {
        let lv_Vm = this
        let nmUiObject = {}
        console.log('고객클릭>>>', item)
        // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
        if(this.$bizUtil.isEmpty(item.chnlCustId)) {
          this.fn_OpenInsCustInfo(item)
          return
        }

        nmUiObject = {
          chnlCustId : item.chnlCustId, //고객채널 ID
          parentId   : 'MSPCM310D', //화면ID+
          cnsltNo : this.cnsltInfo.userId,
          custNm : item.custNm
        }

        // window.vue.getStore('progress').dispatch('FULL')
        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      },

      /*******************************************************
      * function명  : fn_OpenMSPCM311P
      * 설명        : 상세보기 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_OpenMSPCM311P: function () {
        let lv_vm = this
        this.popup311 = this.$bottomModal.open(MSPCM311P, {
          
          properties: {
            pPage: 'MSPCM310D',
            selectedCustInfo: lv_vm.selectItems[0]
          },
          listeners: {
            onPositive: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup311)
            },
            onNegative: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup311)
            },
          }
        })
      },      
      
      /*******************************************************
      * function명  : fn_HistoryBack
      * 설명        : 뒤로가기 버튼
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_HistoryBack: function () {
        let cnt = window.vue.getStore('cmStore').getters.getState.viewCntCM303M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', 0)
        // 조회를 하지 않았으면 뒤로가기
        if (this.checkedCnt > 0) this.fn_confirm('', '', cnt)
        else this.$router.push({name: 'MSPBC002M'}) //this.$router.go(cnt)
      },

      /*******************************************************
      * function명  : fn_OpenBs1
      * 설명        : sheet open
      ******************************************************/
      fn_OpenBs1() {   
        let lv_vm = this
        if(this.checkedCnt > 0) return      
        
        this.$refs.nsbottomsheet1.open()
        var targets = document.querySelectorAll('.mo-page__contents-wrapper .mo-page__contents > .ur-box-container')
        targets[0].classList.add('bottom-move-test')
      },
      
      /*******************************************************
      * function명  : fn_CloseBs1
      * 설명        : sheet close
      ******************************************************/      
      fn_CloseBs1() {
        this.$refs.nsbottomsheet1.close()
      },

    /******************************************************************************
     * Function명 : fn_OpenInsCustInfo
     * 설명       : 고객카드 생성 팝업
     ******************************************************************************/
    fn_OpenInsCustInfo (custInfo) {
      if (custInfo.rrn === undefined) {
        this.$showSnackbar('생년월일이 없습니다.')
      } else {
        this.pObjmsg = {
          cnsltNo: this.cnsltInfo.userId, // 컨설턴트번호(ZA_CHNL_ID)
          custId: custInfo.custId, // 고객ID(PARTNER)
          custNm: custInfo.custNm, // 고객명
          knb: custInfo.rrn, // 주민번호
          birthDay: this.$bizUtil.addBirthFrontYear(custInfo.rrn.substring(0, 6)), // 생년월일
          gender: (custInfo.sxdsCdNm !== undefined) ? custInfo.sxdsCdNm : this.fn_GetSxdsNmByRrn(custInfo) // 성별
        }

        // console.log('pObjmsg >>>>>', this.pObjmsg)

        // 고객카드생성 바텀시트 오픈
        this.$refs.bottomSheet_CustInfo.open()
      }
    },

    /******************************************************************************
     * Function명 : fn_GetSxdsNmByRrn
     * 설명       : 주민번호로 성별 구하기
     ******************************************************************************/
    fn_GetSxdsNmByRrn (custInfo) {
      let rtnVal = ''
      let sexDist = custInfo.rrn.substring(6,7)

      if (sexDist === '1' || sexDist === '3' || sexDist === '5' || sexDist === '7') {
        rtnVal = '남'
      } else if (sexDist === '2' || sexDist === '4' || sexDist === '6' || sexDist === '8') {
        rtnVal = '여'
      }

      return rtnVal
    },

    /******************************************************************************
     * Function명 : fn_Btn_C_OnClick
     * 설명       : 고객카드생성 취소 버튼
     ******************************************************************************/
    fn_Btn_C_OnClick () {
      // 고객카드생성 바텀시트 닫기
      this.$refs.bottomSheet_CustInfo.close()
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        this.fn_InsCustInfo()
      }
    },

    /******************************************************************************
     * Function명 : fn_InsCustInfo
     * 설명       : 통합고객 정보를 채널고객으로 등록
     ******************************************************************************/
    fn_InsCustInfo () {
      const lv_Vm = this
      const trnstId = 'txTSSCM55I1'
      const auth = 'I'

      let confirm = lv_Vm.getStore('confirm')
      let iCNewCustRegVO = lv_Vm.fn_SET_OCC() // 고객 저장 데이터 셋팅
      let pParams = iCNewCustRegVO
      console.log('고객카드 생성 전>>', iCNewCustRegVO)
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
        console.log('고객카드 생성 후>>', response)
          if (response.body !== null && response.body !== '' && response.body.chnlCustId !== null && response.body.chnlCustId !== '') {
            // console.log(response.body.chnlCustId)
            // 고객카드 저장 완료 후 바텀시트 닫고
            lv_Vm.$refs.bottomSheet_CustInfo.close()
            lv_Vm.positionUp = false
            lv_Vm.$refs.nsbottomsheet2.close()
            console.log('고객카드 생성 후1>>', response.body)
            // 리스트 재조회
            lv_Vm.fn_Btn_S_OnClick()
            // 네임UI 오픈
            let custInfo = {
              chnlCustId: response.body.chnlCustId,
              custNm: ''
            }
            lv_Vm.fn_OpenCustInfoBs(custInfo)
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
     /*********************************************************
      * Function명: fn_OpenMSPCM116P
      * 설명: 전체고객 목록 검색 팝업 호출
      *********************************************************/
      fn_OpenMSPCM116P () {
        let lv_vm = this
        this.popup116 = this.$bottomModal.open(MSPCM116P, {
          
          properties: {
            pPage: 'MSPCM303M',
            pOrgData: lv_vm.pOrgData310D, // 최근 조직 조회 데이터
            pSearchKeyword: lv_vm.searchKeyword, // 고객명
            pIsMngr: lv_vm.isMngr, // 지점장 여부

            // 공통 팝업을 사용하므로 prop 에러를 제거하기 위해 넣어주는 변수
            pOrgData115M: {},
            pOrgData124M: {},
          },
          listeners: {
            onPopupSearch: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup116)
              lv_vm.searchKeyword = pData.rtnData.searchKeyword
              // 마지막 조직 조회 데이터 셋팅
              lv_vm.pOrgData310D = pData.rtnData.pOrgData
              lv_vm.currentCnsltInfo.userId = pData.rtnData.cnsltEno
              lv_vm.currentCnsltInfo.onpstFofOrgNo = pData.rtnData.fofOrgCd
              lv_vm.currentCnsltInfo.onpstDofNo = pData.rtnData.dofOrgCd
              lv_vm.resultInput = false
              lv_vm.selectItems = []
              lv_vm.checkedCnt = 0
              lv_vm.isOneOrMoreCheck = false
              // if (lv_vm.currentCnsltInfo.blckTypNm === 'N') {
                // 조회 시작
                lv_vm.fn_Btn_S_OnClick()
                // lv_vm.fn_InitSearch()
              // }
            }
          }
        })
      },

    /******************************************************************************
     * Function명 : fn_SET_OCC
     * 설명       : 고객 저장 데이터 셋팅
     ******************************************************************************/
    fn_SET_OCC () {
      let param = {}

      param.prcsfCd = 'I'
      param.condWhlCnt = 0
      param.cnsltNo = this.pObjmsg.cnsltNo
      param.custId = this.pObjmsg.custId
      param.custNm = this.pObjmsg.custNm
      param.knb = this.pObjmsg.knb
      param.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220) *** 신태블릿용 추가 검토 필요
      param.trtSystmPathCd = '22' // 처리시스템경로코드(사랑온 고정 구분값 : 22) *** 신태블릿용 추가 검토 필요
      param.custDtlTypCd = this.fn_USR_getNatyGb(this.pObjmsg.knb) // 고객상세유형코드 셋팅

      if (this.pObjmsg.knb.length > -6) {
        param.chnlCustScCd = '01'
        param.ralDob = this.$bizUtil.addBirthFrontYear(this.pObjmsg.knb.substring(0, 6))
      } else {
        param.chnlCustScCd = '02'
        param.ralDob = ' '
      }

      param.iCNewCustRegListVO = [{
        prcsfCd : param.prcsfCd,
        chnlCustScCd : param.chnlCustScCd,
        custId : param.custId,
        custNm : param.custNm,
        knb : this.pObjmsg.knb,
        cnsltNo : param.cnsltNo,
        ralDob : param.ralDob,
        mnCustRltnCd : "01",
        mnCustRltnCdNm : "본인"
      }]

      return param
    },
     /*********************************************************
      * Function명: fn_OpenMSPCM307P_BS
      * 설명: 결과입력 전 방문결과부실입력금지 팝업 호출
      *********************************************************/
      fn_OpenMSPCM307P_BS () {
      
        let lv_vm = this
        let popup307_BS = this.$bottomModal.open(MSPCM307P_BS, {
          
          properties: {
            pPage: 'MSPCM303M',
          },
          listeners: {
            onPositive307Bs: () => {
              lv_vm.$bottomModal.close(popup307_BS)
              console.log('--------------------------------------')
              console.log('fn_OpenMSPCM307P_BS onPositive307Bs')
              console.log('--------------------------------------')
              lv_vm.fn_OpenMSPCM307P()
                
            },
            onNegative307Bs: () => {
              console.log('--------------------------------------')
              console.log('fn_OpenMSPCM307P_BS onNegative307Bs')
              console.log('--------------------------------------')
              lv_vm.$bottomModal.close(popup307_BS)
            },
          }
        })
      },

      /*********************************************************
      * Function명: fn_OpenMSPCM307P
      * 설명: 결과입력 팝업 호출
      *********************************************************/
      fn_OpenMSPCM307P () {
        let lv_vm = this
        let msg = ''
        lv_vm.selectedCustInfo = this.selectItems[0]
        msg = lv_vm.fn_Validate307(lv_vm.selectedCustInfo)
        console.log('--------------------------------------')
        console.log('fn_OpenMSPCM307P start')
        console.log('--------------------------------------')
        if (this.isServicePoss === false) {
          console.log('--------------------------------------')
          console.log('this.isServicePoss : true')
          console.log('--------------------------------------')
          lv_vm.$addSnackbar('지금은 서비스신청 가능 시간이 아닙니다.\n(신청시간 : 08:00 ~ 21:00)')
          return
        }
        if (msg !== ''){
          console.log('--------------------------------------')
          console.log('msg : true')
          console.log('--------------------------------------')
           lv_vm.$addSnackbar(msg)
           return
        } 
        lv_vm.selectedCustInfo.custSrvcTypCdNm = lv_vm.commCdList.CUST_SRVC_TYP_CD.filter(item => item.cdVal === lv_vm.selectedCustInfo.custSrvcTypCd)[0].cdValNm
        console.log('--------------------------------------')
        console.log('lv_vm.selectedCustInfo.custSrvcTypCdNm')
        console.log(lv_vm.selectedCustInfo.custSrvcTypCdNm)
        console.log('--------------------------------------')
        console.log('lv_vm.selectedCustInfo>>>>', lv_vm.selectedCustInfo)

        let popup307 = this.$bottomModal.open(MSPCM307P, {
          properties: {
            pPage: 'MSPCM303M',
            selectedCustInfo: lv_vm.selectedCustInfo
          },
          listeners: {
            onPositive307p: () => {
              console.log('--------------------------------------')
              console.log('MSPCM303M CLOSE : onPositive307p')
              console.log('--------------------------------------')
              lv_vm.$bottomModal.close(popup307)
              lv_vm.$refs.nsbottomsheet2.close()
              lv_vm.fn_Btn_S_OnClick()
              lv_vm.selectItems = []
              lv_vm.checkedCnt = 0
              lv_vm.isOneOrMoreCheck = false
            },
            onNegative307p: () => {
              lv_vm.$bottomModal.close(popup307)
              console.log('--------------------------------------')
              console.log('MSPCM303M CLOSE : onNegative307p')
              console.log('--------------------------------------')
            },
          }
        })
      },

     /******************************************************************************
      * Function명: fn_Validate307
      * 설명: input값에 대한 validation 수행함수
      ******************************************************************************/     
      fn_Validate307: function (param) {
        let lv_vm = this
        let lv_custInfo = param
        let msg = ''
        console.log('xxxxx', Number(lv_vm.vstInptStrYmdNow), Number(lv_vm.vstInptEndYmdNow))
        if (lv_vm.$bizUtil.isEmpty(lv_custInfo.custSrvcAplYmd)) {
          msg = '서비스 신청을 하지 않은 고객입니다.'

        } else if (lv_custInfo.custSrvcAplYmd === this.lv_Today) {
          msg = '방문(택배)후에 입력해주세요.'
          
        }else if (Number(this.lv_Today) < Number(lv_vm.vstInptStrYmdNow) || Number(this.lv_Today) > Number(lv_vm.vstInptEndYmdNow)) {
          lv_vm.vstFrom = Number(lv_vm.vstInptStrYmdNow.substring(4, 6)) + '월 ' + Number(lv_vm.vstInptStrYmdNow.substring(6, 8)) + '일'
          lv_vm.vstTo = Number(lv_vm.vstInptEndYmdNow.substring(4, 6)) + '월 ' + Number(lv_vm.vstInptEndYmdNow.substring(6, 8)) + '일'
          msg = '결과입력기간이 아닙니다.\n입력기간은' + lv_vm.vstFrom + '부터 ' + lv_vm.vstTo + ' 까지 입니다'
        }
        return msg
      },

    /******************************************************************************
     * Function명 : fn_USR_getNatyGb
     * 설명       : 고객상세유형코드 셋팅
     ******************************************************************************/
      fn_USR_getNatyGb (knb) {
        var natyGb = '11'
        if (knb.length < 7) {
          return natyGb
        }
        var knbGb = knb.substring(6, 7)

        if (knbGb === '1' || knbGb === '2' || knbGb === '3' || knbGb === '4') {
          natyGb = '11'
        } else if (knbGb === '5' || knbGb === '6' || knbGb === '7' || knbGb === '8') {
          natyGb = '12'
        }
        return natyGb
      },

      /******************************************************************************
       * @description confirm popup
       * @param {String} title 제목
       * @param {String} message confirm 내용
       ******************************************************************************/
      fn_confirm(title, message, cnt) {
        let lv_Vm = this
        let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              type: 'B',
              content: backMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
                lv_Vm.$router.go(cnt)
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      },
      
    }
  };
</script>

<style scoped>
.ns-insideWidthAuto {
  height: calc(100vh - 56px) !important; 
  width: 100% !important;
}
</style>